import {
	Button,
	CircularProgress,
	IconButton,
	Snackbar,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	FormControl
} from "@mui/material";
import { Close } from "@mui/icons-material";
import {
	default as React,
	useEffect,
	useReducer,
	useState
} from "react";
import moment from "moment";
import {
	useHistory,
	/*useLocation,*/ useParams
} from "react-router-dom";
import styled from "styled-components";
import { /*useBarUtils,*/ useSelectedBar } from "../actions/bars";
import { useSetHeaderTitle } from "../actions/headerContext";
import {
	usePromotionUtils,
	useSelectedPromotion
} from "../actions/promotions";
import { usePromotionPlans } from "../actions/promotionPlans";
import { auth, functions } from "../services/firebase";
import { THEME_MAP } from "../theme";
import { BackDrop } from "../components/BackDrop";
import { useUserRoles } from "../globalstore";
import dayjs from "dayjs";

export function PromotionDetail() {
	const { id } = useParams();
	const promotionSnapshot = useSelectedPromotion();
	const { fetchPromotionById } = usePromotionUtils();

	const [promotion, setPromotion] = useState();
	const [isFetching, setIsFetching] = useState();

	const [isOpen, setIsOpen] = useState(false);
	const [note, setNote] = useState("");

	const setHeaderTitle = useSetHeaderTitle();
	const closeNote = (_event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setNote("");
		setIsOpen(false);
	};

	const openNote = (message) => {
		setNote(message);
		setIsOpen(true);
	};

	useEffect(() => {
		setHeaderTitle(id === "new" && "Create new promotion");
		if (promotionSnapshot && promotionSnapshot.id === id) {
			let _promotion = promotionSnapshot;
			delete _promotion.bar;
			setPromotion(_promotion);
		} else if (id) {
			setPromotion(null);
			if (id !== "new") {
				setIsFetching(true);
				fetchPromotionById(id).then(() => setIsFetching(false));
			}
		}
	}, [promotionSnapshot, id, fetchPromotionById, setHeaderTitle]);

	if (isFetching)
		return (
			<Container className={"is-loading"}>
				<LoadingFog className={"loading-fog is-loading"}>
					<CircularProgress color="primary" />
				</LoadingFog>
			</Container>
		);

	return (
		<Container>
			<PromotionInfoSection
				promotion={promotion}
				openNote={openNote}
			/>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				open={isOpen}
				autoHideDuration={3000}
				onClose={closeNote}
				message={note}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={closeNote}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			/>
		</Container>
	);
}
const Container = styled.div`
	height: 100%;
	width: 100%;

	padding: 24px;
	overflow: auto;
	position: relative;

	&.is-loading {
		overflow: hidden;
	}

	.promotion-actions {
		max-width: 800px;
		margin: auto;
		display: flex;
		justify-content: flex-end;
	}
`;

const LoadingFog = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
	justify-content: center;
	background: rgba(255, 235, 235, 0.6);
	&.is-loading {
		display: flex;
	}
`;

// Creating a new promotion from promotion/new
function PromotionInfoSection({ promotion, openNote }) {
	const history = useHistory();
	const { currentUser } = auth;
	const {
		userRoles: { email }
	} = useUserRoles();
	const { id } = useParams();
	const isNew = id === "new";
	const selectedBar = useSelectedBar();
	const { fetchPromotionPlans, promotionPlans } =
		usePromotionPlans();

	const [selectedPromotionPlan, setSelectedPromotionPlan] =
		useState(null);

	const [showBackDrop, setShowBackdrop] = useState(false);
	const { savePromotion } = usePromotionUtils();
	const [promotionState, promotionDispatch] = useReducer(
		promotionInfoReducer,
		emptyPromotionState,
		promotionInfoInit
	);
	useEffect(() => {
		let promo = promotion;
		if (promotion && !promotion["barId"]) {
			promo = promotion.data();
		}
		promotionDispatch({
			type: "reset",
			payload: promo || emptyPromotionState
		});
	}, [promotion]);

	useEffect(() => {
		if (!promotionPlans.length) {
			fetchPromotionPlans();
		}
	}, [fetchPromotionPlans, promotionPlans]);

	useEffect(() => {
		if (
			promotionState.promotionPlanId &&
			promotionPlans.length > 0
		) {
			const promotionPlan = promotionPlans.find(
				(promotionPlan) =>
					promotionPlan.id === promotionState.promotionPlanId
			);
			if (promotionPlan) {
				setSelectedPromotionPlan(promotionPlan);
			}
		}
	}, [promotionState, promotionPlans]);

	const handleInputChange = (event) => {
		promotionDispatch({
			type: "update",
			payload: {
				name: event.target.name,
				value: event.target.value
			}
		});
	};

	const handleSave = async (e) => {
		e.preventDefault();

		const { ...promotion } = promotionState;

		if (isNew) promotion.createdBy = currentUser.uid;

		if (validateData(promotion)) {
			if (isNew) {
				promotion.barId = selectedBar.id;
				promotion.userId = currentUser.uid;

				setShowBackdrop(true);
				let startingHours = "",
					endingHours = "";

				promotionPlans.forEach((item) => {
					if (item.id === promotion.promotionPlanId) {
						endingHours = moment()
							.add(item.metadata.duration_in_minutes, "m")
							.format();
						startingHours = moment().format();
					}
				});
				const promotionId = await savePromotion(id, {
					promotionName: promotion.promotionName,
					promotionCoverImage: promotion.promotionCoverImage,
					promotionImages: promotion.promotionImages,
					promotionDays: moment().format("YYYY-MM-DD"),
					promotionEndingHours: endingHours,
					promotionStartingHours: startingHours,
					promotionPlanId: promotion.promotionPlanId,
					hasChanged: true,
					status: false,
					createdBy: promotion.createdBy,
					barId: promotion.barId,
					userId: promotion.userId
				});
				if (promotionId) {
					functions
						.httpsCallable("selectPromotionPlan")({
							barId: selectedBar.id,
							promotionId,
							promotionProductId: selectedPromotionPlan.id,
							promotionPriceId:
								selectedPromotionPlan.default_price.id,
							customerEmail: email,
							currentLocation: window.location.href
						})
						.then((url) => {
							window.location.href = url.data;
						});
				}
			} else {
				savePromotion(id, promotion).then(() => {
					openNote("Sucessfully updated promotion");
					history.goBack();
				});
			}
		} else {
			openNote("Promotion must have Name and Duration");
		}
	};

	const handleCancel = (e) => {
		e.preventDefault();
		history.goBack();
	};

	const validateData = (promotion) => {
		return !(
			!promotion.promotionName?.trim() || !selectedPromotionPlan
		);
	};

	return (
		<PromotionInfoContainer>
			<div className="form-section large">
				<TextField
					className="header-like"
					label="Promotion Name"
					name="promotionName"
					fullWidth
					inputProps={{
						style: {
							fontSize: "1.8rem"
						},
						maxLength: 50
					}}
					value={promotionState.promotionName}
					onChange={handleInputChange}
					required
				/>
				<TextField
					label="Promotion Description"
					name="promotionDescription"
					fullWidth
					multiline
					value={promotionState.promotionDescription}
					onChange={handleInputChange}
				/>
				<FormControl fullWidth>
					<InputLabel id="duration-select-label" shrink>
						Duration *
					</InputLabel>
					<Select
						disabled={!isNew}
						notched
						value={promotionState.promotionPlanId}
						onChange={(event) => {
							promotionDispatch({
								type: "update",
								payload: {
									name: "promotionPlanId",
									value: event.target.value
								}
							});
						}}
						label="Duration *"
						inputProps={{ "aria-label": "Duration" }}
						labelId="duration-select-label"
						color="primary"
						required
					>
						<MenuItem disabled value={null}>
							-- Select Duration --
						</MenuItem>
						{promotionPlans.map((plan) => (
							<MenuItem key={plan.id} value={plan.id}>
								{plan.metadata.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{!isNew && (
					<>
						<TextField
							label="Start date"
							disabled
							name="promotionStartDate"
							fullWidth
							multiline
							value={dayjs(
								new Date(
									promotionState.promotionStartingHours
										.seconds *
										1000 +
										promotionState.promotionStartingHours
											.nanoseconds /
											1000000
								)
							).format("MM/DD/YYYY, hh:mm A")}
							onChange={handleInputChange}
						/>
						<TextField
							label="End date"
							disabled
							name="promotionEndDate"
							fullWidth
							multiline
							value={dayjs(
								new Date(
									promotionState.promotionEndingHours
										.seconds *
										1000 +
										promotionState.promotionEndingHours
											.nanoseconds /
											1000000
								)
							).format("MM/DD/YYYY, hh:mm A")}
							onChange={handleInputChange}
						/>
					</>
				)}
			</div>
			<p>
				Your promotion will start at the moment you complete the
				payment.
			</p>
			<div className="form-footer">
				{isNew ? (
					<Button
						color="primary"
						variant={"outlined"}
						onClick={handleCancel}
					>
						Cancel
					</Button>
				) : null}
				<Button
					color="primary"
					variant={
						promotionState.hasChanged || isNew
							? "contained"
							: "outlined"
					}
					onClick={handleSave}
				>
					{isNew
						? `Pay Now${
								selectedPromotionPlan
									? `($${
											selectedPromotionPlan.default_price
												.unit_amount / 100
										})` //We need to divide by 100 to convert to dollars, because stripe prices are in cents.
									: ""
							}`
						: "Save"}
				</Button>
			</div>
			{showBackDrop ? (
				<BackDrop showBackDrop={showBackDrop} />
			) : null}
		</PromotionInfoContainer>
	);
}
const emptyPromotionState = {
	promotionName: "",

	promotionCoverImage: "",
	promotionImages: [],
	promotionDescription: "",

	promotionDays: "",
	promotionEndingHours: "",
	promotionStartingHours: "",
	promotionPlanId: "",
	hasChanged: false,
	status: false

	//position: {
	//geopoint: {
	//latitude: 0,
	//longitude: 0,
	//},
	//},
};

function promotionInfoInit(promotionInfo) {
	return {
		original: promotionInfo,
		...promotionInfo,
		hasChanged: false
	};
}

function promotionInfoReducer(state, action) {
	const { type, payload } = action;
	let hasChanged = false;
	switch (type) {
		case "reset":
			return promotionInfoInit(payload || state.original);
		case "update":
			hasChanged = true;
			return {
				...state,
				[payload.name]: payload.value,
				hasChanged
			};
		default:
			return state;
	}
}

const PromotionInfoContainer = styled.form`
	max-width: 800px;
	margin: auto;
	background: ${THEME_MAP["color-basic-100"]};
	border-radius: 4px;
	border: 1px solid ${THEME_MAP["color-basic-400"]};
	padding: 16px 24px;

	min-height: 100px;
	margin-bottom: 16px;

	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.form-section {
		display: flex;
		flex-direction: column;
		width: 48%;
		margin-bottom: 32px;

		&.large {
			width: 100%;
		}

		& > div {
			margin: 8px 0;
		}
	}

	.image-uploads {
		background: ${THEME_MAP["color-basic-300"]};
		border-radius: 3px;
		height: 300px;

		display: flex;
		align-items: center;
		justify-content: space-evenly;

		img {
			height: 268px;
			width: auto;
		}
	}

	.form-footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		& > button {
			margin-left: 16px;
		}
	}
`;

// const emptyCategories = {
// 	monday: {},
// 	tuesday: {},
// 	wednesday: {},
// 	thursday: {},
// 	friday: {},
// 	saturday: {},
// 	sunday: {}
// };
// const DAYS = [
// 	"monday",
// 	"tuesday",
// 	"wednesday",
// 	"thursday",
// 	"friday",
// 	"saturday",
// 	"sunday"
// ];

// const emptyPromotions = {
// 	monday: {},
// 	tuesday: {},
// 	wednesday: {},
// 	thursday: {},
// 	friday: {},
// 	saturday: {},
// 	sunday: {}
// };

// const resizeExample = () => {
//   let imageRef = { files: [new File()] };
//   resizeImage({
//     file: imageRef.files[0],
//     maxSize: 500,
//   })
//     .then(function (resizedImage) {
//       // upload resized image
//     })
//     .catch(function (err) {
//       console.error(err);
//     });
// };
